const pricingConstants = {
  PRICING_REFERRAL: 'g6WDsnRXYSrEAV9',
  PRICING_REFERRAL_14T: 'SPxBeLTDMvGMawc',
  PRICING_DEFAULT: 'BU2GRzG2hVFwS5a',
  PRICING_COACHING: 'lRcZaTLavJy0kp1',
  PRICING_COACHING_FULL: 'CKipi0UKvEl9KC8',
  PRICING_COACHING_TRIAL: 'sj9tESOvWFziyu5',
  PRICING_UPSELL_FAMILY: 'T2JaZPfrzNVfJbe',
  PRICING_UPSELL_FAMILY_6: '2GCCpQiAh8kpjdF',
  PRICING_MONTHLY: 'hKT8pdmjBgaVzSV',
  PRICING_YEARLY_6999_7DAYS: 'BU2GRzG2hVFwS5a',
  PRICING_YEARLY_6999_7DAYS_25OFF: 'FxajxgrGhpAPDaS',
  PRICING_INTRO_OFFER: 'BT3XPJIPhlPfKsn',
  PRICING_YEARLY_5999_7DAYS: '7GGdrvosQGJ6urG',
  PRICING_UPSELL_FAMILY_6_30DAYS: 'buPiKRFTz8hFD4M',
  PRICING_YEARLY_5999_7DAYS_FOLLOW_UP: 'PyJXr4JTjINBRxZ',
  PRICING_YEARLY_4999_7DAYS_FOLLOW_UP: 'PslW6nDoVZx9aw4',
  PRICING_SIX_MONTH_4999_STRIPE_OFFER: 'xhgflHXuzVLflie',
  PRICING_SIX_MONTH_4499_STRIPE_OFFER: 'AsFlvkrP7WMNBOU',
  PRICING_UPSELL_FAMILY_7499_STRIPE_OFFER: 'PdYBUu5YQT7gDfH',
  PRICING_UPSELL_FAMILY_7999_STRIPE_OFFER: '6vTv8LlzSOQxUn1',
  PRICING_NO_TRIAL_3_SKU_DEFAULT: 'McwjYTcblA8OZbg',
  COUNTRY_BASED_PRICING_ISO: ['KR', 'FR', 'BR', 'IN', 'JP', 'MX', 'ES', 'DE'],
};

module.exports = pricingConstants;
